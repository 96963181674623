<template>
  <div>
    <!-- Modal to payment begins -->
    <div
      class="modal fade fundme"
      id="bankOptions"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="margin-bottom: 55px">
          <div class="close_bank">
            <h6>{{ dataForPayment.headerCaption }}</h6>
            <small
              data-dismiss="modal"
              aria-label="Close"
              @click="closePaymentModal"
              >x</small
            >
          </div>
          <div class="modal-body">
            <div class="funds_wrap">
              <h5 class="fund_title">{{ dataForPayment.subtitle }}</h5>
              <h4
                class="fund_amount"
                v-if="amount && dataForPayment.paymentType == 'autodebitsafe'"
              >
                &#8358;{{
                  Number(dataForPayment.payableAmount).toLocaleString()
                }}
              </h4>
              <h4
                class="fund_amount"
                v-if="dataForPayment.paymentType != 'autodebitsafe'"
              >
                &#8358;{{
                  Number(dataForPayment.payableAmount).toLocaleString()
                }}
              </h4>
              <div class="funds_options">
                <div ref="safe" class="fund_type">
                  <!-- payFromSafe(); -->
                  <button
                    @click="
                      closeModalAll();
                      responseData = loadingState;
                    "
                    class="btn btn-block safe"
                    data-toggle="modal"
                    data-target="#confirmsafepayment"
                  >
                    <img src="../assets/images/safe.svg" alt="safe" />
                    Pay From SAFE
                    <i
                      class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                      v-if="responseData == 'loading'"
                    ></i>
                  </button>
                </div>
                <div ref="paystack" class="fund_type">
                  <paystack
                    class="paystackBtn"
                    :amount="amount * 100"
                    :email="email"
                    :paystackkey="PUBLIC_KEY"
                    :reference="reference"
                    :callback="processPayment"
                    :channels="channels"
                    :close="close"
                  >
                    <div class="paystack_div">
                      <img src="../assets/images/block.svg" alt="" />
                      <small>
                        Paystack
                      </small>
                    </div>
                  </paystack>
                </div>

                <div ref="transfer" class="fund_type d-none">
                  <button class="btn btn-block bank" @click="bankTransfer()">
                    <img
                      src="../assets/images/double-arrow.svg"
                      alt="transfer"
                    />
                    Bank Transfer
                  </button>
                </div>

                <div ref="wallet" class="fund_type d-none">
                  <button class="btn btn-block wallet_withdraw" disabled>
                    <img
                      src="../assets/images/pay-wallet.svg"
                      alt="transfer"
                      width="36px"
                    />Pay from wallet
                  </button>
                </div>

                <!-- <div class="fund_type">
                  <button class="btn btn-block paystack" disabled>
                    <img
                      src="../assets/images/block.svg"
                      alt="transfer"
                    />Paystack
                  </button>
                </div> -->

                <div ref="flutterwave" class="fund_type d-none">
                  <button class="btn btn-block flutter" disabled>
                    <img
                      src="../assets/images/flutter.svg"
                      alt="transfer"
                    />Flutterwave
                  </button>
                </div>
                <div ref="enaira" class="fund_type d-none">
                  <button class="btn btn-block enaira" disabled>
                    <img
                      src="../assets/images/e-naira.svg"
                      alt="transfer"
                    />e-Naira
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to payment ends -->

    <!-- Modal for bank statment upload begins -->
    <div
      class="modal fade"
      id="showpayment"
      tabindex="-1"
      role="dialog"
      aria-labelledby="showpayment"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="margin-bottom: 55px">
          <div class="modal-header payment_header_holder">
            <img
              v-if="headerText2"
              @click="switchView(0)"
              class="back__arrow"
              src="../assets/images/arrow2-white.svg"
              alt=""
            />
            <img
              v-if="headerText3"
              @click="switchView(1)"
              class="back__arrow"
              src="../assets/images/arrow2-white.svg"
              alt=""
            />
            <h5
              v-if="headerText1"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Rent Payment
            </h5>
            <h5
              v-if="headerText2"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Proof of payment upload.
            </h5>
            <h5
              v-if="headerText3"
              class="modal-title payment-header"
              id="exampleModalLongTitle"
            >
              Call Shelta.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="close_times">&times;</span>
            </button>
          </div>
          <div id="onee" class="modal-body">
            <div class="">
              <div class="dot-holder">
                <span class="step active"></span>
                <span class="step"></span>
                <span class="step"></span>
                <!-- <span class="step"></span> -->
              </div>
              <div class="double_arrow_holder">
                <img src="../assets/images/double-arrow-blue.svg" alt="back" />
                <p>Bank Transfer</p>
              </div>
              <div class="form-outter">
                <div class="first-step">
                  <div class="number__holder">
                    <p class="span-count">1</p>
                    <p>
                      Transfer the amount due to the acount number below
                    </p>
                  </div>

                  <div class="sheltaAccount__details">
                    <div>
                      <p>Amount:</p>
                      <p>&#8358;{{ Number(amount).toLocaleString() }}</p>
                    </div>
                    <div>
                      <p>Account Name:</p>
                      <p>Shelta Panacea Limited</p>
                    </div>
                    <div>
                      <p>Account Number :</p>
                      <p>0708490113</p>
                    </div>
                    <div>
                      <p>Bank :</p>
                      <p>Guarantee Trust Bank</p>
                    </div>
                  </div>
                  <div class="number__holder second__number">
                    <p class="span-count">2</p>
                    <p>
                      After the transfer is completed, please keep your payment
                      receipt by screenshot or photo and upload it in the next
                      step.
                    </p>
                  </div>

                  <div class="desclaimer__text">
                    <p>
                      Kindly note that your rent starts running 48 hours after
                      your payment is approved
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <button
                @click="switchView(1)"
                type="button"
                class="btn next-btn btn-block"
              >
                Next
              </button>
            </div>
          </div>

          <div id="twoo" class="modal-body">
            <div class="container-fluid">
              <div class="dot-holder">
                <span class="step"></span>
                <span class="step active"></span>
                <span class="step"></span>
              </div>
              <div class="form-outter">
                <div class="second-step">
                  <p class="info__text">
                    Please upload the payment receipt so that we can find your
                    payment.
                  </p>
                  <div class="dateOf__payment">
                    <label for="">Date of payment <span>*</span></label>
                    <input
                      class="form-control"
                      name="date"
                      type="date"
                      placeholder="Select date of payment"
                    />
                  </div>

                  <div class="upload__file">
                    <div class="dropzone">
                      <input
                        @change="selectFile"
                        class="input-file"
                        ref="file"
                        type="file"
                      />
                      <img src="../assets/images/file-upload.svg" alt="" />
                      <img
                        v-if="fileselected"
                        class="actual_image"
                        :src="filePath"
                        alt="reciept"
                      />
                      <p
                        v-if="!uploading && !fileselected"
                        class="call-to-action"
                      >
                        Drag and drop or
                        <span class="browse__span">browse</span> your files
                      </p>
                      <p
                        v-if="uploading"
                        class="call-to-action"
                        style="color: green;"
                      >
                        Uploding your reciept...
                      </p>
                      <!-- <p v-if="uploading" class="progress-bar">
                        <progress
                          class="progress is-primary"
                          :value="progress"
                          max="100"
                          >{{ progress }} %</progress
                        >
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <!-- switchView(2) -->
              <button
                @click="sendFile"
                type="button"
                class="btn next-btn btn-block"
              >
                Submit
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="loaderM"
                ></i>
              </button>
            </div>
          </div>

          <div id="threee" class="modal-body">
            <div class="container-fluid">
              <div class="dot-holder">
                <span class="step"></span>
                <span class="step"></span>
                <span class="step active"></span>
              </div>
              <div class="form-outter">
                <div class="third-step">
                  <img src="../assets/images/rent-call.svg" alt="" />
                  <p>
                    Submited successfully ! We will confirm your payment and
                    update your status within 48 hours. For enquiries please
                    call our customer service agent on :
                  </p>
                </div>
              </div>
            </div>
            <div class="modal-foot">
              <button type="button" class="btn call-btn">
                <a href="tel:2348061796909">
                  <img src="../assets/images/phone-conect.svg" alt="" />
                  08061796909
                </a>
              </button>
            </div>
          </div>

          <!-- <div class="modal-footer modal-foot">
            
            <button type="button" class="btn next-btn btn-block">Next</button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Modal for bank statment upload ends -->

    <!-- Modal for paywith safe confirmation begins -->
    <div
      class="modal fade"
      id="confirmsafepayment"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmsafepayment"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="margin-bottom: 55px; border: none;">
          <div class="modal-header payment_header_holder">
            <h5 class="modal-title payment-header" id="exampleModalLongTitle">
              Pay from SAFE
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="close_times">&times;</span>
            </button>
          </div>

          <div>
            <p class="confirm-safe-text">Are you sure you want to contine?</p>
          </div>
          <div class="safepaybuton">
            <div class="btn no" data-dismiss="modal" aria-label="Close">
              Cancel
            </div>
            <div class="btn yes" @click="payFromSafe()">
              Continue
              <i
                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                v-if="responseData == 'loading'"
              ></i>
            </div>
            <!-- <p class="d-none">{{ responseData2 }}</p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for paywith safe confirmation begins -->
  </div>
</template>

<script>
import { Service } from "../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();
import paystack from "vue-paystack";
import { staticdata } from "../../src/data";
export default {
  name: "PaymentModal",
  components: {
    paystack
  },
  props: [
    "dataForPayment",
    "paystackReference",
    "action",
    "responseData",
    "closee"
  ],
  data() {
    return {
      loader: false,
      loaderM: false,
      //   header: "",
      // modalHeader: "",
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      uploadFile: "",
      user: "",
      error: false,
      uploading: false,
      fileselected: false,
      filePath: "",
      file: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      paymentData: "",
      // PUBLIC_KEY: staticdata.paystackkeytest,
      PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      fullName: "",
      reference: "",
      amount: "",
      optionData: "",
      routePath: "",
      routeName: "",
      prop: "",
      bankTransferUrl: "",
      channels: ["card", "bank", "ussd", "qr", "bank_transfer"],
      loadingState: this.responseData
    };
  },
  computed: {
    welcome() {
      return `${this.dataForPayment}`;
    }
    // responseData2() {
    //   if (this.responseData == "response") {
    //     $("#confirmsafepayment")
    //       .removeClass("fade")
    //       .modal("hide");
    //     //   setTimeout(() => {
    //     //   this.responseData = "";
    //     //   // console.log("this is the first message");
    //     // }, 1000);
    //   }
    //   return this.responseData;
    // }
  },
  // beforeMount() {},
  watch: {
    responseData: function(newVal, oldValue) {
      // watch it
      // console.log("Prop changed: ", newVal);
      if (newVal == "response" || newVal == "") {
        $("#confirmsafepayment")
          .removeClass("fade")
          .modal("hide");
        //   setTimeout(() => {
        //   this.responseData = "";
        //   // console.log("this is the first message");
        // }, 1000);
      }
    }
  },
  created() {
    this.$root.$refs.A = this;
    this.$root.$refs.X = this;
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.fullName = this.user.firstname + this.user.lastname;
    this.dataForPayment === this.dataForPayment;
    this.responseData === this.responseData;
    this.routePath = this.$route.path;
    this.routeName = this.$route.name;
  },
  beforeDestroy() {
    this.loadingState = ""; // save props data to itself's data and deal with it
  },
  methods: {
    closeModalAll() {
      $("#bankOptions")
        .removeClass("fade")
        .modal("hide");
        // console.log("Hey closed");
    },
    processPayment(res) {
      // console.log("clikedd", res);
      const ref = res.reference;
      if (res.status === "success") {
        this.$emit("paystackReference", ref);
        $("#bankOptions")
          .removeClass("fade")
          .modal("hide");
        if (this.dataForPayment.paymentType === "autodebitsafe") {
          this.$root.$refs.C.scheduleSafeFunding();
          // return setTimeout(() => {
          //   this.$root.$refs.C.scheduleSafeFunding();
          // }, 1000);
        }
        if (this.dataForPayment.paymentType === "fundbluesafe") {
          this.$root.$refs.C.fundBlueSafe();
          // return setTimeout(() => {
          //   this.$root.$refs.C.fundBlueSafe();
          // }, 1000);
        }
        if (this.dataForPayment.paymentType === "fundwalletinvest") {
          this.$root.$refs.fwi.fundWalletInvestmentPaystack();
        }
        if (
          this.dataForPayment.paymentType === "rentpayment" ||
          this.dataForPayment.paymentType === "rentpaymentswitch"
        ) {
          return this.$emit("action", {
            type: "paystack",
            action: true,
            ref: ref
          });
        }
      }
    },
    // Paystack close function or cancel payment
    close() {
      $("#bankOptions")
        .removeClass("fade")
        .modal("hide");
      // this.$root.$refs.C.closedPaystack();
      // console.log("You closed checkout page");
      return this.$emit("closee", { close: "yes" });
    },

    closePaymentModal() {
      // this.$root.$refs.C.closedPaystack();
      $("#bankOptions")
        .removeClass("fade")
        .modal("hide");
      // console.log("You closed payment modal");
      return this.$emit("closee", { close: "yes" });
    },

    payFromSafe() {
      // this.$root.$refs.C.fundBlueSafe();
      // console.log("response paymentsafe", this.loadingState);
      if (
        this.dataForPayment.paymentType === "rentpayment" ||
        this.dataForPayment.paymentType === "rentpaymentswitch"
      ) {
        // $("#bankOptions")
        //   .removeClass("fade")
        //   .modal("hide");
        return this.$emit("action", { type: "payfromsafe", action: true });
      }
    },

    openPaymentOptionsModal(propInfo) {
      this.prop = propInfo;
      this.optionData = this.dataForPayment;
      if (
        this.optionData.paymentType === "autodebitsafe" ||
        this.optionData.paymentType === "fundbluesafe"
      ) {
        this.$refs.wallet.style.display = "none";
        this.$refs.transfer.style.display = "none";
        this.$refs.flutterwave.style.display = "none";
        this.$refs.enaira.style.display = "none";
        this.$refs.safe.style.display = "none";
      }
      if (this.optionData.paymentType === "rentpayment") {
        this.$refs.wallet.style.display = "none";
        this.$refs.flutterwave.style.display = "none";
        this.$refs.enaira.style.display = "none";
      }
      if (this.optionData.paymentType === "rentpaymentswitch") {
        this.$refs.safe.style.display = "none";
        this.$refs.wallet.style.display = "none";
        this.$refs.flutterwave.style.display = "none";
        this.$refs.enaira.style.display = "none";
      }
      if (this.optionData.paymentType === "fundwalletinvest") {
        this.$refs.wallet.style.display = "none";
        this.$refs.flutterwave.style.display = "none";
        this.$refs.enaira.style.display = "none";
        this.$refs.safe.style.display = "none";
      }
      if (this.optionData.paymentType === "autodebitsafe") {
        this.amount = this.dataForPayment.amount;
      } else {
        this.amount = this.dataForPayment.payableAmount;
      }
      // this.amount = this.dataForPayment.payableAmount;
      // this.tokenAmount = this.dataForPayment.tokenAmount;
      // if (this.optionData.paymentType === "fundbluesafe") {
      //   this.$refs.wallet.style.display = "none";
      //   this.$refs.transfer.style.display = "none";
      //   this.$refs.flutterwave.style.display = "none";
      //   this.$refs.enaira.style.display = "none";
      // }

      $("#bankOptions")
        .addClass("fade")
        .modal("show");
    },
    bankTransfer() {
      if (
        this.dataForPayment.paymentType === "rentpayment" ||
        this.dataForPayment.paymentType === "rentpaymentswitch"
      ) {
        $("#bankOptions")
          .removeClass("fade")
          .modal("hide");
        $("#showpayment")
          .addClass("fade")
          .modal("show");
        // if (this.routePath )
      }
      if (this.routePath === "/dashboard/tenant") {
        // console.log("trnsfer URL", this.bankTransferUrl)
        this.bankTransferUrl = "paynow";
      }
      if (this.routePath === "/dashboard/rented") {
        // console.log("trnsfer URL", this.bankTransferUrl)
        this.bankTransferUrl = "paynow";
      }
      if (this.routePath === `/dashboard/rent/${this.prop.pin}`) {
        this.bankTransferUrl = "applyforrent";
      }
      if (this.routeName === "SwitchTenancyAgreement") {
        this.bankTransferUrl = "switchnow";
      }
    },
    // payFromBank() {
    //   $("#bankOptions")
    //     .removeClass("fade")
    //     .modal("hide");
    //   $("#showpayment")
    //     .addClass("fade")
    //     .modal("show");
    // },
    payModal: function() {
      $("#propertyPin")
        .removeClass("fade")
        .modal("hide");
      $("#showpfundmeayment")
        .addClass("fade")
        .modal("show");
    },
    getUserProfile: function() {
      Api.getRequest(`getuserbyid/${this.id}`).then(res => {
        if (res.status == 401) {
          window.localStorage.clear();
          return (window.location.href = "https://myshelta.com/login");
        }
        this.user = res.data.user;
        this.fulldata = res.data.fulldata;
      });
    },
    switchView(event) {
      if (event === 1) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "block";
        document.getElementById("threee").style.display = "none";
        this.headerText1 = false;
        this.headerText2 = true;
        this.headerText3 = false;
      } else if (event === 2) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "block";
        this.headerText1 = false;
        this.headerText2 = false;
        this.headerText3 = true;
      } else if (event === 0) {
        document.getElementById("onee").style.display = "block";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "none";
        // this.headerText2 = false;
      } else {
        document.getElementById("twoo").style.display = "none";
        document.getElementById("onee").style.display = "none";
        document.getElementById("threee").style.display = "block";
      }
    },

    selectFile() {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.filePath = URL.createObjectURL(file);
      this.fileName = file.name;
      if (this.file) {
        this.fileselected = true;
      } else {
        this.fileselected = false;
      }
    },
    async sendFile() {
      this.loaderM = true;
      // this.switchView(2)
      const file = this.$refs.file.files[0];
      // this.file = file;
      if (!this.file) {
        this.loaderM = false;
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      const formData = new FormData();
      // For Dashboard payment
      if (
        this.bankTransferUrl === "paynow" &&
        this.routePath === "/dashboard/tenant"
      ) {
        formData.append("file", this.file);
        formData.append("amount", this.amount);
        formData.append("id", this.prop.nextpaymentid);
      }
      if (
        this.routePath === "/dashboard/rented" &&
        this.bankTransferUrl === "paynow"
      ) {
        formData.append("file", this.file);
        formData.append("amount", this.amount);
        formData.append("id", this.prop.switchid);
      }
      if (this.bankTransferUrl == "applyforrent") {
        formData.append("file", this.file);
        formData.append("userid", this.user.id);
        formData.append("propertyid", this.prop.id);
        formData.append("modeofpaymentid", this.prop.paymodeid);
        formData.append("addedservicesids", this.prop.addedServicesId);
      }
      if (this.bankTransferUrl === "switchnow") {
        formData.append("file", this.file);
        formData.append(
          "destinationTenantModeOfPaymentsId",
          Number(this.prop.destinationTenantModeOfPaymentsId)
        );
        formData.append("switchid", this.prop.switchid);
      }
      // console.log("form Data", formData);
      try {
        this.uploading = true;
        await Api.postRequest(`${this.bankTransferUrl}`, formData, {
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        })
          .then(res => {
            this.$store.commit("setApiSuccess", "Success");
            this.switchView(2);
            this.uploading = false;
            this.loaderM = false;
            if (res.data.success) {
              this.moreTenantData();
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
            // this.message = "File has been uploaded";
            // this.file = '';
            // this.error = false;
          })
          .catch(err => {
            // console.log(err);
            this.loaderM = false;
            this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.confirm-safe-text {
  font-size: 18px;
  margin-top: 30px;
  font-family: Gotham;
  font-style: normal;
}
.safepaybuton {
  margin-top: 20px;
  border: none;
  margin-bottom: 20px;
  button {
    font-size: 17px;
    font-family: Gotham;
    font-style: normal;
  }
  .no {
    border: 1px solid #ea0033;
    color: #ea0033;
    margin-right: 10px;
  }
  .yes {
    background: $primary;
    color: $secondary;
  }
}

.close_bank {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;
  h6 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.fund_title {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $black;
}
.fund_amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  color: $black;
}
.funds_wrap {
  // border: 1px solid #E5E5E5;
  box-sizing: border-box;
  margin: 40px 52px;
  border-radius: 10px;
}

.funds_options {
  margin: 10px 22px;
}

.fund_type {
  button:hover {
    color: #ffffff !important;
  }
}
.fund_type {
  padding: 5px 15px;
  button {
    height: 40px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 7px 25px;
    align-items: center;
    img {
      padding-right: 10px;
    }
  }
  .safe {
    background-color: $primary;
    outline: 0;
    box-shadow: none;
  }
  .bank {
    background-color: #15a2a2;
    outline: 0;
    box-shadow: none;
  }
  .wallet_withdraw {
    background: #1d95c8;
    outline: 0;
    box-shadow: none;
  }
  .paystack {
    background: #56c1f2;
    outline: 0;
    box-shadow: none;
  }
  .flutter {
    background: $yellow;
    outline: 0;
    box-shadow: none;
  }
  .enaira {
    background: #16780d;
    margin-bottom: 5px;
    outline: 0;
    box-shadow: none;
  }

  .paystackBtn {
    color: $secondary;
    border: none;
    background: #56c1f2;
    // background: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // margin-left: 10px;
    box-shadow: none;
    outline: 0;
    // max-width: 262px;
    width: 100%;
    border-radius: 5px;
    font-size: 15px !important;
    // height: 51px;
    small {
      font-size: 15px;
    }
  }
  .paystackBtn:hover {
    color: #1d3c4b;
    font-size: 17px;
    cursor: pointer;
  }
}

// Bank transfer upload modal
.payment_header_holder {
  background: $primary;
}

.payment-header {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: $secondary;
}

.close_times {
  color: #ea0000;
  background: $secondary;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0px 6px;
}

.dot-holder {
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}

.double_arrow_holder {
  p {
    margin-top: 5px;
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
  }
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  // opacity: 1;
  background-color: $primary;
}
.step.finish {
  background-color: #04aa6d;
}

#twoo {
  display: none;
}
#threee {
  display: none;
}

//  First step starts here
.number__holder {
  text-align: left;
  display: flex;
  p:first-child {
    background: $primary;
    // padding: 0.3rem 0.69rem;
    border-radius: 50px;
    color: $secondary;
    text-align: center;
    align-items: baseline;
    margin-right: 16px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    line-height: 11px;
    // width: 2rem;
    // height: 1.5rem;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    color: $black;
    margin-right: 1rem;
  }
}

.sheltaAccount__details {
  background: rgba(64, 123, 255, 0.46);
  margin: 0 -16px 15px -16px;
  // margin-bottom: 15px;
  div {
    display: flex;
    justify-content: space-between;
    margin: 0 3.5rem;
    padding-top: 10px;
    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      text-align: left;
      margin-bottom: 0;
    }

    p:nth-child(2) {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      text-align: right;
      margin-bottom: 0;
    }
  }
}

.next-btn {
  background: $primary;
  border-radius: 5px !important;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $secondary;
  margin-bottom: 1rem;
  margin-top: 2rem;
  box-shadow: none;
}

.desclaimer__text {
  // padding-top: 4rem;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: $primary;
    text-align: left;
    margin-left: 2.5rem;
  }
}

.modal-foot {
  border-top: none;
}
// Firts modal steps ends

// Second modal steo start
.info__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: $black;
}

.dateOf__payment {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    color: $black;
  }
  input {
    width: 100%;
    border-bottom: 0.5px solid #6c6c6c !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.upload__file {
  .dropzone {
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 0;
    padding: 50px 5px;
    min-height: 200px;
    cursor: pointer;
    position: relative;
    p {
      margin-bottom: 0;
      padding-top: 10px;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }
    .browse__span {
      color: $primary;
    }
  }
}
.dropzone:hover {
  background-color: lightgrey;
}

// .input-file {
//   opacity: 0;
//   width: 100%;
//   left: 0%;
//   height: 200px;
//   position: absolute;
//   cursor: pointer;
// }
.input-file {
  opacity: 0;
  width: 100%;
  top: 0;
  left: 0%;
  // height: 200px;
  position: absolute;
  height: 100%;
  object-fit: contain;
  z-index: 10;
  cursor: pointer;
}

.actual_image {
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  position: absolute;
  left: 0%;
  cursor: pointer;
}

.third-step {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    // display: flex;
    // align-items: flex-end;
    text-align: center;
    color: $black;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.call-btn {
  background: #f2f5fe;
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 10px;

  // width: 154px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  box-shadow: none;
  // display: flex;
  // align-items: flex-end;
  text-align: center;
  color: $black;
  padding: 6px 26px;
  a {
    img {
      width: 27.01px;
      margin-right: 1rem;
    }
  }
}

.back__arrow {
  padding-top: 0.7rem;
  cursor: pointer;
  margin-right: 10px;
}

// Second modal step ends

// Bank transfer modal upload ends

@media screen and (max-width: 599px) {
  // Bank transfer

  .funds_wrap {
    margin: 40px 0px;
  }
  .funds_options {
    margin: 10px 0px;
  }
  .info__text {
    text-align: justify;
  }
  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }
  .sheltaAccount__details {
    div {
      margin: 5px 15px;
      p:first-child {
        font-size: 13px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .second__number {
    padding-top: 2rem;
  }
  .desclaimer__text {
    // padding-top: 1rem;
    p {
      font-size: 13px;
      line-height: 25px;
      text-align: left;
    }
  }
  .payment-header {
    font-size: 15px;
  }
  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }
  .call-btn {
    font-size: 13px;
    padding: 6px 34px;
    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }
  .third-step {
    img {
      width: 7rem;
    }
  }
  // Bank transfer
}
</style>
